<template>
  <div class="UserSubscribersList">
    <SingleCard class="noContentCard" v-if="!$loading && !total">
      <div class="icon"><iconic name="lock1a" :original="true" /></div>
      <div class="text">{{ $locale["no_has_subscribers"] }}</div>
    </SingleCard>
    <UserReportCard v-for="user in subscriptors" :key="user._id" :user="user" :card="{ desc: getTimeLapse({ date: user.activated }) }" />
  </div>
</template>

<script>
export default {
  components: {
    UserReportCard: () => import("./UserReportCard.vue"),
  },
  methods: {
    getUserSubscribers({ page = 1 } = {}) {
      this.$store.dispatch("getUserSubscribers", { page });
    },
  },
  mounted: function() {
    this.getUserSubscribers();
  },
  computed: {
    subscriptors: function() {
      return this.$store.state.creatorSubscribers.subscribers.items;
    },
    total: function() {
      return this.$store.state.creatorSubscribers.subscribers.total;
    },
  },
};
</script>
